import React from "react";
import { graphql } from 'gatsby'
import LatestPost from "../components/blog/home/latestPost";
import BlogCategory from "../components/blog/home/blogCategory";
import { groupBy, resetWebflow } from "../utils/common";
import { HelmetDatoCms } from "gatsby-source-datocms";
import ReactMarkdown from "react-markdown";
import Notification from "../components/header/notification";
import Layout from "../layouts/headerFooterOnly";

export default ({
  data: {
    allDatoCmsBlogPost: { edges },
    datoCmsBlogHome: {
      pageTitle,
      pageDescription,
      featuredSection,
      subscribeHeading,
      subscribeDescription,
      hubspotFormId,
      seoMetaTags,
      notificationPage
    },
    featureBlogs: { featureBlogs },
    blogsByCategory: { allBlogs },
    allDatoCmsNotification: { edges: notifications }
  }
}) => {
  resetWebflow("5b9e45b108470e7589f9e478");
  const latestPostDate = Object.assign(
    { subscribeHeading, subscribeDescription, hubspotFormId },
    edges[0].node
  );
  const blogsByCategory = groupBy(allBlogs, x => x.node.category.categoryName);
  const blogsCategoryTmpl = Array.from(blogsByCategory).map(category => {
    let allBlogsByCategory = category[1];
    const categoryName = category[0];
    let sortedBlogs = allBlogsByCategory
      .sort(function (first, second) {
        let firstItemPublishedDate = new Date(first.node.publishedOn);
        let secondItemPublishedDate = new Date(second.node.publishedOn);
        if (firstItemPublishedDate < secondItemPublishedDate) return 1;
        if (firstItemPublishedDate > secondItemPublishedDate) return -1;
        return 0;
      });
    const topThreeBlogs = sortedBlogs.slice(0, 3);

    const seeAllLink = category[1][0].node.category.slug;
    return (
      <BlogCategory data={topThreeBlogs} category={categoryName} seeAllLink={seeAllLink} />
    );
  });
  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <Notification
        notifications={notifications}
        linkedPage={notificationPage}
      />
      <header className="main-header about">
        <div className="container">
          <h1 className="h-nofixedwidth">{pageTitle}</h1>
          <div className="sm-spacing" />
          <div className="p center-align">
            <ReactMarkdown source={pageDescription} escapeHtml={false} />
          </div>
        </div>
        <div className="container grid" />
      </header>
      <LatestPost data={latestPostDate} />
      <section className="white-section event">
        <div className="container grid">
          <BlogCategory data={featureBlogs} category={featuredSection} />
          {blogsCategoryTmpl}
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query BlogHomeQuery {
    datoCmsBlogHome {
      id
      pageTitle
      pageDescription
      featuredSection
      subscribeHeading
      subscribeDescription
      hubspotFormId
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      notificationPage {
        id
        page
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
    allDatoCmsBlogPost(limit: 1, sort: { fields: [publishedOn], order: DESC }) {
      edges {
        node {
          id
          slug
          blogTitle
          featureImage {
            alt
            url
          }
          author {
            authorImage {
              alt
              url
            }
            authorName
          }
        }
      }
    }
    featureBlogs: allDatoCmsBlogPost(
      limit: 3
      sort: { fields: [publishedOn], order: DESC }
      filter: { isfeature: { eq: true } }
    ) {
      featureBlogs: edges {
        node {
          id
          slug
          blogTitle
          publishedOn(formatString: "MMM D, YYYY")
          featureImage {
            alt
            url
          }
          author {
            authorImage {
              alt
              url
            }
            authorName
          }
        }
      }
    }
    blogsByCategory: allDatoCmsBlogPost(
      sort: { fields: [publishedOn], order: DESC }
    ) {
      allBlogs: edges {
        node {
          id
          slug
          blogTitle
          publishedOn(formatString: "MMM D, YYYY")
          featureImage {
            alt
            url
          }
          author {
            authorImage {
              alt
              url
            }
            authorName
          }
          category {
            categoryName
            slug
          }
        }
      }
    }
  }
`;
