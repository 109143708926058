import React from "react";
import { createHubSpotForm, resizeAndConvertImage } from "../../../utils/common";
import emailImg from "../../../styles/images/email-gray.svg";
import { Link } from "gatsby";
import ReactMarkdown from "react-markdown";

export default ({
  data: {
    slug,
    blogTitle,
    featureImage,
    author,
    subscribeHeading,
    subscribeDescription,
    hubspotFormId
  }
}) => {
  createHubSpotForm({
    formId: hubspotFormId,
    targetId: "#blogHomeHubspotForm",
    css: ""
  });
  return (
    <React.Fragment>
      <section>
        <div className="container grid">
          <div className="top-featured-post">
            <Link to={`/blog/${slug}`}>
              <div className="dark-gradient">
                <h1 className="h-nofixedwidth">{blogTitle}</h1>
                <div className="sm-spacing" />
                {author && (
                  <div className="blog-author-content featured">
                    {author.authorImage && (
                      <img
                        src={author.authorImage.url}
                        alt={author.authorImage.alt}
                        className="author-avatar"
                      />
                    )}
                    <div className="author-name">{author.authorName}</div>
                  </div>
                )}
              </div>
              <img
                src={`${featureImage.url}${resizeAndConvertImage(1400)}`}
                alt={featureImage.alt}
                className="blog-post-thumbnail"
              />
            </Link>
          </div>
          <div className="subscribe-blog w-hidden-medium w-hidden-small w-hidden-tiny">
            <img src={emailImg} alt="subscribe-form" />
            <div className="sm-spacing" />
            <h4 className="h4">
              <ReactMarkdown source={subscribeHeading} escapeHtml={false} />
            </h4>
            <div className="sm-spacing" />
            <div className="small-copy" style={{ letterSpacing: "-0.3px" }}>
              {subscribeDescription}
            </div>
            <div className="sm-spacing" />
            <div className="w-embed w-script">
              <div id="blogHomeHubspotForm" />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
